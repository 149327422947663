<template>
  <a :href="VonageAPIStatusPageLink" target="_blank">
    <span class="label rounded-l-xl">{{ $t(`footer.apiStatus`) }}</span>
    <span class="status rounded-r-xl" :class="status.indicator"> {{ status.description }}</span>
  </a>
</template>

<script setup lang="ts">
import { VonageAPIStatusPageLink } from '@/constants';
import { getApiStatus } from '@/utils/apiStatus';

const status = await getApiStatus();
</script>

<style scoped lang="scss">
span {
  font: var(--vvd-typography-base-condensed);
  font-weight: 600;

  @apply inline-block shrink-0 m-0 py-1 px-2 align-bottom whitespace-nowrap;

  &.label {
    background: var(--vvd-color-neutral-600);
    color: var(--vvd-color-canvas);
  }

  &.status {
    color: var(--vvd-color-canvas);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);

    &.none {
      background: var(--vvd-color-success-500);
    }

    // colors from the status page to keep it consistent
    &.minor {
      background: #f1c40f;
    }

    &.major {
      background: #e67e22;
    }

    &.critical {
      background: #e74c3c;
    }
  }
}
</style>
