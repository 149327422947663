import { VonageAPIStatusPageLink } from '@/constants';

export interface APIStatus {
  indicator: 'none' | 'minor' | 'major' | 'critical';
  description: string;
}

interface APIStatusResponse {
  status: APIStatus;
}

export const getApiStatus = async (): Promise<APIStatus> => {
  try {
    return (
      await $fetch<APIStatusResponse>('/api/v2/status.json', {
        baseURL: VonageAPIStatusPageLink,
      })
    ).status;
  } catch (e) {
    return { indicator: 'critical', description: 'Status Unknown' };
  }
};
