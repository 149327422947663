import { CDNUrl } from '@/constants';
import { useHead } from 'nuxt/app';

export function useVividSSR() {
  useHead({
    script: [
      {
        src: new URL('/assets/vivid-ssr/3.51.0/vivid-ssr.min.js?prefix=portal', CDNUrl).toString(),
      },
    ],
  });
}
