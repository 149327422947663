<template>
  <div class="bg-black" data-testid="footer">
    <SectionWithMaxWidth>
      <div class="flex flex-col gap-8 sm:flex-row sm:gap-0 py-[64px] px-[16px] sm:px-0">
        <div class="flex flex-row mr-[64px] 2xl:mr-[96px]">
          <div class="flex flex-col gap-6">
            <div class="flex justify-center">
              <FooterLogo />
            </div>
            <ClientOnly>
              <div class="flex justify-center">
                <FooterAPIStatusBadge />
              </div>
            </ClientOnly>
            <div class="flex justify-center">
              <FooterSocialLinks :links="story.content.socialLinks" />
            </div>
          </div>
        </div>
        <div
          class="flex-grow grid grid-cols-1 md:grid-cols-2 gap-[32px] xl:flex xl:flex-row xl:gap-[64px] 2xl:gap-[96px]"
        >
          <FooterSection v-for="section in story.content.sections" :key="section._uid" :section="section" />
        </div>
      </div>
    </SectionWithMaxWidth>
  </div>
</template>

<script setup lang="ts">
import FooterLogo from '@/components/footer/FooterLogo.vue';
import FooterAPIStatusBadge from '@/components/footer/FooterAPIStatusBadge.vue';
import FooterSection from '@/components/footer/FooterSection.vue';
import { useBrandingStore } from '@/store/branding';
import type { FooterWidgetStory } from '@/types/storyblok';
import useAsyncStoryblokWithI18n from '@/utils/storyblok/useAsycStoryblokWithI18n';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';

const brandingStore = useBrandingStore();

const story: FooterWidgetStory = await useAsyncStoryblokWithI18n(
  `branding/${brandingStore.data?.vendorId}/config/footer`
);
</script>

<style lang="scss" scoped>
.footer {
  background: var(--vvd-color-canvas-text);
  backdrop-filter: blur(25px);
}
</style>
