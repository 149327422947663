<template>
  <a :href="VonageWebsiteUrl" target="_blank" class="w-[220px] h-[28px]">
    <img v-bind="logo" width="220px" height="28px" />
  </a>
</template>

<script setup lang="ts">
import VonageBrandLogo from '@/assets/footer/vonage-logo.svg';
import PoweredByVonageBrandLogo from '@/assets/footer/powered-by-vonage.png';
import { computed } from 'vue';
import useOrgId from '@/utils/useOrgId';
import { VonageOrgID, VonageWebsiteUrl } from '@/constants';

const orgId = useOrgId();

const logo = computed(() =>
  orgId.value === VonageOrgID
    ? {
        src: VonageBrandLogo,
        alt: 'Vonage - Part Of Ericsson',
      }
    : {
        src: PoweredByVonageBrandLogo,
        alt: 'Powered By Vonage',
      }
);
</script>
