<template>
  <NuxtLink :href="link.href" :target="target">
    {{ link.label }}
  </NuxtLink>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';
import type { Link } from '@/types/storyblok';
import { isAbsoluteUrl } from '@/utils/url';

const props = defineProps({
  link: { type: Object as PropType<Link>, required: true },
});

const target = computed(() => (isAbsoluteUrl(props.link?.href) ? '_blank' : '_self'));
</script>
